.mdk-drawer__content {
  background: transparent;
}

.mdk-header-layout .mdk-drawer__content {
  top: $navbar-height;
}



.mdk-drawer {
  // fix mozilla firefox issue
  z-index: 1;
}

.mdk-drawer-layout {
  overflow: visible;
}
