.button-list {
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;
  margin-left: 0.75rem !important;
}

.button-list > .btn {
  margin-bottom: .75rem;
  margin-left: .5rem;
}

.btn-rounded {
  border-radius: 100px;
}

.btn .material-icons {
  font-size:18px;
  vertical-align: middle;
  position:relative;
  top: -1px;
}

.btn-light {
  @include button-variant($background: white, $border: $border-color);
}

.btn-danger,
.btn-info,
.btn-warning,
.btn-success {
  &,
  &:hover,
  &:focus,
  &:active {
    color: white;
  }
}

.btn-flush {
  padding: 0;
  background: none;
  color: inherit;
  line-height: 1;
  box-shadow: none;
}

.btn-rounded-social {
  border-radius: 50%;
  width:30px;
  height:30px;
  line-height: 26px;
  text-align:center;
  vertical-align: middle;
  padding: 0;
}
