
.v-heading-flex {
  display:contents;
}

.v-heading-flex img {
  margin-right:10px;
}

.title-backbutton i {
  font-size: 2rem;
  color:$primary;
  /*margin-left: -10px;*/
  margin-right: 10px;
}

.cursor-pointer{
  cursor: pointer;
}

select {
  border: 0;
}

.ui-state-highlight {
  height: 1.5em; line-height: 1.5em;
}

.signature-pad{
  border: $border-color 1px solid;
  border-radius: 0.25rem;
}

.v-button{
  cursor: pointer;
}
